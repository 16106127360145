import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyMarkdown = lazy(
  () => import("./markdown-BnZvcp-F.js").then((module) => ({
    default: module.Markdown
  }))
);
function Markdown({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyMarkdown, { ...props });
}
const useMarkdownContent = () => {
  return useMemo(
    () => ({
      markdown: {
        component: Markdown
      }
    }),
    []
  );
};
export {
  LazyMarkdown as Markdown,
  useMarkdownContent
};
